import swedishMessages from 'ra-language-swedish';

export default {
    ...swedishMessages,
    resources: {
        enums: {
            publishingStatus: {
                draft: 'Utkast',
                published: 'Publicerad',
                archived: 'Arkiverad',
                ready: 'Klar'
            },
            headerType: {
                image: 'Bild',
                slideshow: 'Bildspel',
                video: 'Video',
            },
            severity: {
                info: 'Info',
                important: 'Viktigt',
                warning: 'Varning',
            },
            mapConnection: {
                None: 'Ingen',
                PointOfInterest: 'Besöksmål',
                AreaOfInterest: 'Area',
            },
            recurrence: {
                None: 'Ingen',
                Daily: 'Dagligen',
                OnEntrance: 'Vid start',
            },
            displayStatus:{
                Display: 'Visa',
                Hide: 'Dölj',
                Scheduled: 'Schemalagd',
            }
        },
        menu: {
            dashboard: 'Översikt',
            users: 'Användare',
            appSettings: 'App-inställningar',
            settings: 'Inställningar',
            terms: 'Villkor',
            timePeriods: 'Tidsperioder',
            startPage: 'Startsida',
            areas: 'Områden',
            information: 'Information',
            tags: 'Taggar',
            areaOfInterests: 'Area',
            categories: 'Kategorier',
            pointOfInterests: 'Besöksmål',
            paths: 'Stigar',
            snowmobiles: 'Skoterleder',
            recommendations: 'Rekommendationer',
            subPaths: 'Delsträcka',
            freeRidingAreas: 'Friåkningsområden',
            grooming: 'Preparering',
            groups: 'Grupper',
            crossCountryPaths: 'Längdskidspår',
            snowmobilePaths: 'Snöskoterspår',
        },
        page: {
            dashboard: {
                welcomeAdmin: 'Välkommen administratör!',
                welcomeGroomer: 'Välkommen!',
            },
        },
        users: {
            setActive: 'Aktivera',
            selectOne: 'Välj',
            yes: 'Ja',
            no: 'Nej',
            fields: {
                search: 'Sök',
                isActive: 'Aktiv',
                firstName: 'Förnamn',
                lastName: 'Efternamn',
                email: 'E-post',
                phone: 'Telefon',
                validatePhone: 'Måste vara ett giltigt telefonnummer',
                role: 'Roll',
                roles: {
                    user: 'Användare',
                    admin: 'Administratör',
                    groomer: 'Pistör'
                },
                password: 'Nytt lösenord',
                createdAt: 'Skapad',
                updatedAt: 'Uppdaterad'
            }
        },
        settings: {
            fields: {
                termsId: 'Villkor',
                summerSeasonId: 'Sommarsäsong',
                winterSeasonId: 'Vintersäsong',
                updatedAt: 'Uppdaterad'
            }
        },
        terms: {
            fields: {
                status: 'Status',
                name: 'Namn',
                title: 'Titel',
                terms: 'Villkor',
                createdAt: 'Skapad',
                updatedAt: 'Uppdaterad'
            }
        },
        timeperiods: {
            fields: {
                search: 'Sök',
                name: 'Namn',
                start: 'Start',
                end: 'Slut'
            }
        },
        areas: {
            fields: {
                search: 'Sök',
                name: 'Namn',
                createdAt: 'Skapad',
                updatedAt: 'Uppdaterad'
            }
        },
        startpages: {
            fields: {
                title: 'Titel',
                body: 'Innehåll',
                headerType: 'Typ',
                medias: 'Media',
                updatedAt: 'Uppdaterad',
                priority: 'Prioritet',
                name: 'Namn',
                entryType: 'Typ av inträde',
            },
            editRows: 'Redigera rader',
            create: 'Skapa',
            editRowEntries: 'Redigera radposter'
        },
        information: {
            fields: {
                status: 'Status',
                severity: 'Nivå',
                title: 'Titel',
                description: 'Beskrivning',
                startAt: 'Startar',
                endAt: 'Slutar',
                buttonText: 'Knapptext',
                buttonUrl: 'Knappens URL',
                tag_ids: 'Taggar',
                mapConnection: 'Relation',
                recurrence: 'Upprepning',
                createdAt: 'Skapad',
                updatedAt: 'Uppdaterad'
            }
        },
        informationtags: {
            fields: {
                search: 'Sök',
                name: 'Namn',
                createdAt: 'Skapad',
                updatedAt: 'Uppdaterad'
            }
        },
        aois: {
            filterText: 'Alla kategorier',
            fields: {
                search: 'Sök',
                name: 'Namn',
                createdAt: 'Skapad',
                updatedAt: 'Uppdaterad',
                category: 'Kategori',
                area_ids: 'Område',
                areas: 'Områden',
                area: 'Område',
                display: 'Visa',
                description: 'Beskrivning',
                geoJsonFilename: 'Aktuell GeoJson fil',
                geojson: 'Ny Geo-Json fil',
                timePeriodId: 'Tidsperiod',
                medias: 'Media'
            }
        },
        aoicategories: {
            fields: {
                isListed: 'Listad?',
                name: 'Namn',
                pageTitle: 'Titel',
                createdAt: 'Skapad',
                updatedAt: 'Uppdaterad',
                description: 'Beskrivning',
                media: 'Media'
            }
        },
        pois: {
            areaFilterText: 'Alla områden',
            categoryFilterText: 'Alla kategorier',
            fields: {
                area: 'Område',
                areas: 'Områden',
                area_ids: 'Områden',
                category: 'Kategori',
                name:'Namn',
                display: 'Visa',
                updatedAt: 'Uppdaterad',
                latitude: "Latitud",
                longitude: 'Longitud',
                elevation: 'Altitud',
                url: 'Url',
                description: 'Beskrivning',
                pointOfInterestCategoryId: 'Kategori',
                timePeriodId: 'Tidsperiod',
                medias: 'Media'
            }
        },
        poicategories: {
            fields: {
                q: 'Sök',
                isListed: 'Listad?',
                name: 'Namn',
                pageTitle: 'Titel',
                createdAt: 'Skapad',
                updatedAt: 'Uppdaterad',
                description: 'Beskrivning',
                media: 'Media'
            }
        },
        paths: {
            fields: {
                setStatus: 'Sätt status',
                selectOne: 'Välj',
                groomed: 'Preparerad',
                updateStatusTitle: 'Uppdatera led med status',
                openStatusContent: 'Är du säker på att du vill sätta status till "öppen" för dessa objekt?',
                closedStatusContent: 'Är du säker på att du vill sätta status till "stängd" för dessa objekt?',
                notSetStatusContent: 'Är du säker på att du vill ställa in statusen till "ej inställd" för dessa objekt?',
                updateGroomedStatusTitle: 'Uppdatera stigar som preparerad',
                updateGroomedStatusContent: 'Är du säker på att du vill preparera dessa objekt?',
                q: 'Sök',
                area: 'Område',
                areas: 'Områden',
                areasEmptyText: 'Alla områden',
                category: 'Kategori',
                display: 'Visa',
                categoryEmptyText: 'Alla kategorier',
                status: 'Status',
                statusEmptyText: 'Alla statusar',
                number: 'Nummer',
                name: 'Namn',
                groomedAt: 'Preparerad',
                tag_ids: 'Tagg',
                length: 'Längd (km)',
                start: 'Startnamn',
                end: 'Slutnamn',
                lastGPXFilename: 'Aktuell GPX fil',
                difficulty: 'Svårighet',
                veryEasy: 'Mycket lätt',
                easy: 'Lätt',
                medium: 'Medel',
                hard: 'Svår',
                open: 'Öppen',
                closed: 'Stängd',
                relatedPois: 'Relaterade platser',
                tags: 'Taggar',
                gpx: 'GPX fil',
                description: 'Beskrivning',
                timePeriodId: 'Tidsperiod',
                medias: 'Media',
                createdAt: 'Skapad',
                updatedAt: 'Uppdaterad',
            }
        },
        pathcategories: {
            fields: {
                name: 'Namn',
                pageTitle: 'Sidtitel',
                description: 'Beskrivning',
                media: 'Media',
                createdAt: 'Skapad',
                updatedAt: 'Uppdaterad',
            }
        },
        tags: {
            fields: {
                q: 'Sök',
                name: 'Namn',
                createdAt: 'Skapad',
                updatedAt: 'Uppdaterad',
            }
        },
       'grooming/filter/paths': {
            fields: {
                q: 'Sök',
                name: 'Namn',
                filterType: 'Typ',
                crossCountry:'Längdskidåkning', 
                snowmobile: 'Snöskoter',
                path_ids: 'Leder',
                subPath_ids: 'Delsträcka',
                createdAt: 'Skapad',
                updatedAt: 'Uppdaterad',
            }  
        },
        'grooming/paths': {
            fields: {
                setStatus: 'Sätt status',
                selectOne: 'Välj en',
                groomed: 'Preparerad',
                q: 'Sök',
                filterpath: 'Grupp',
                pathEmptyText: 'Alla grupper',
                areas: 'Område',
                areaEmptyText: 'Alla områden',
                status: 'Status',
                statusEmptyText: 'Alla statusar',
                open: 'Öppen',
                closed: 'Stängd',
                number: 'Nummer',
                name: 'Namn',
                groomedAt: 'Preparerad',
                updateStatusTitle: 'Uppdatera led med status',
                openStatusContent: 'Är du säker på att du vill sätta status till "öppen" för dessa objekt?',
                closedStatusContent: 'Är du säker på att du vill sätta status till "stängd" för dessa objekt?',
                notSetStatusContent: 'Är du säker på att du vill ställa in statusen till "ej inställd" för dessa objekt?',
                updateGroomedStatusTitle: 'Uppdatera stigar som preparerad',
                updateGroomedStatusContent: 'Är du säker på att du vill preparera dessa objekt?',
            }
        }
    },
};
