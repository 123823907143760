import { makeStyles } from '@mui/styles';
import * as React from "react";
import { useState, useEffect } from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, DateInput, ReferenceArrayInput, SelectArrayInput, ReferenceInput, SelectInput, ImageField, ImageInput, minValue, maxValue, useRecordContext, useGetList, useGetOne, TranslatableInputs, useLocaleState } from 'react-admin';
import { useParams } from 'react-router-dom';
import { DisplayStatus } from '../../shared/enums/display-status.enum';
import { TimePeriod } from '../../shared/interfaces/timePeriod.interface';
import GetTimePeriodRange from '../../shared/services/timePeriod.services';
import { CustomToolbar } from './../../components/customtoolbar/customToolbar.component';
import { locales } from  '../../i18n/languges'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues } from 'react-hook-form';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export const PoiEdit = () => {
    const classes = useStyles();
    const timePeriods = useGetList<TimePeriod>(
        "timeperiods",
        {
            pagination: { page: 1, perPage: 100 },
            sort: { field: "id", order: "ASC" },
        },
    );
    const record = useRecordContext();
    const [selectedTimePeriodId, setSelectedTimePeriodId] = useState<number>(0);
    const [displayValue, setDisplayValue] = useState(DisplayStatus.Display);
    const { id } = useParams();
    const [locale] = useLocaleState();
    const poi = useGetOne("pois", { id: id ?? record?.id });
    const selectedTimePeriod = GetTimePeriodRange(
      selectedTimePeriodId,
      timePeriods.data as any
    );
    const displayOptions = Object.keys(DisplayStatus).map(key => ({
        id: DisplayStatus[key],
        name: `resources.enums.displayStatus.${key}`,
    }));
    const schema = yup.object().shape({
        name: yup.object().shape({
            en: yup.string().required('Name in English is required'),
        }).required('Name is required'),
        latitude: yup.number()
            .required('Latitude is required')
            .min(-90, 'Latitude must be at least -90')
            .max(90, 'Latitude must be at most 90'),
        longitude: yup.number()
            .required('Longitude is required')
            .min(-180, 'Longitude must be at least -180')
            .max(180, 'Longitude must be at most 180'),
        elevation: yup.number().required('Elevation is required'),
        pointOfInterestCategoryId: yup.string().required('Point of Interest Category is required'),
        display: yup.mixed().required('Display status is required'),
        timePeriodId: yup.string().nullable().when('display', {
            is: (value: string | undefined) => value === DisplayStatus.Scheduled,
            then: schema => schema.required('Time period can\'t be empty if display is scheduled'),
            otherwise: schema => schema.nullable()
        }),
    });
    const resolver = yupResolver<FieldValues>(schema);

    useEffect(() => {
      if (!poi.isLoading) {
        setSelectedTimePeriodId(poi?.data?.timePeriodId);
        setDisplayValue(poi?.data?.display);
      }
    }, [poi.isLoading]);

    return (
        <Edit id={record?.id}>
            <SimpleForm toolbar={<CustomToolbar/>} resolver={resolver}>
                <TranslatableInputs fullWidth={true} locales={locales} sx={{marginBottom: '2rem'}} >
                    <TextInput source="name" formClassName={classes.inlineBlock}/>
                    <TextInput source="description" rows={8} multiline={true} fullWidth={true} />
                </TranslatableInputs>
                {/*<TextInput source="icon" />*/}
                <NumberInput source="latitude" min={-90} max={90}/>
                <NumberInput source="longitude" min={-180} max={180}/>
                <NumberInput source="elevation" />
                <TextInput source="url" />
                <ReferenceInput source="pointOfInterestCategoryId" reference="poicategories" sort={{ field: `name.${locale}`, order: 'ASC' }}>
                    <SelectInput optionText={`name.${locale}`} />
                </ReferenceInput>
                <ReferenceArrayInput source="area_ids" reference="areas">
                    <SelectArrayInput optionText={`name.${locale}`} />
                </ReferenceArrayInput>
                <SelectInput
                    source="display"
                    choices={displayOptions}
                    onChange={(e) => setDisplayValue(e.target.value as DisplayStatus)}
                ></SelectInput>
                {displayValue === DisplayStatus.Scheduled && (
                    <ReferenceInput
                        source="timePeriodId"
                        reference="timeperiods"
                        onChange={(e) => setSelectedTimePeriodId(e.target.value)}
                    >
                        <SelectInput
                            optionText="name"
                            helperText={selectedTimePeriod}
                        ></SelectInput>
                    </ReferenceInput>
                )}
                <ImageInput source="medias" accept="image/*" multiple={true}>
                    <ImageField source="src" title="title" />
                </ImageInput>
                <TextInput source="youtubeVideoId" label="YouTube id" formClassName={classes.inlineBlock}/>

                <TextInput source="socialMedia.instagramUrl" label="Instagram url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.facebookUrl" label="Facebook url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.linkedinUrl" label="Linkedin url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.twitterUrl" label="Twitter url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.vimeoUrl" label="Vimeo url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.youtubeUrl" label="Youtube url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.tripadvisorUrl" label="Tripadvisor url" formClassName={classes.inlineBlock}/>
                <DateInput disabled source="createdAt" formClassName={classes.inlineBlock} />
                <DateInput disabled source="updatedAt" formClassName={classes.inlineBlock} />
            </SimpleForm>
        </Edit>
    );
}