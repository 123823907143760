import * as React from "react";
import { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { Pagination, BulkDeleteWithConfirmButton, Button, Confirm, Filter, NullableBooleanInput, ReferenceInput, SelectInput, TextInput, useNotify, useRefresh, useUnselectAll, useUpdateMany, ReferenceArrayField, FunctionField } from 'react-admin';
import { List, Datagrid, TextField, DateField, BooleanField, ReferenceManyField, ArrayField, SingleFieldList, ChipField, useLocaleState, useTranslate } from 'react-admin';
import { PathEdit } from "./path.edit.component";
import { DisplayStatus } from "../../shared/enums/display-status.enum";
import { Status } from "../../shared/enums/status.enum";
import { startCase, toLower } from 'lodash';

const PathBulkActionButtons = (props: any) => {
    const [selectedIds, setSelectedIds] = React.useState(props.selectedIds);
    const [isActive, setIsActive] = React.useState('');

    const [openStatusConfirm, setOpenStatusConfirm] = React.useState(false);
    const [openGroomedConfirm, setOpenGroomedConfirm] = React.useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('paths');
    const translate = useTranslate();

    const [updateManyWithStatus, { isLoading: loadingManyWithStatus }] = useUpdateMany(
        'paths',
        {
            ids: selectedIds,
            data: { status: isActive },
        },
        {
            onSuccess: () => {
                refresh();
                notify('Paths updated');
                unselectAll();
            },
            onError: error => notify(`Error: paths not updated with status: "${isActive}".`, {type: 'warning'}),
        }
    );

    const [updateManyAsGroomed, { isLoading: loadingManyAsGroomed }] = useUpdateMany(
        'paths',
        { 
            ids: selectedIds,
            data: { groomedAt: new Date() },
        },
        {
            onSuccess: () => {
                refresh();
                notify('Paths updated');
                unselectAll();
            },
            onError: error => notify(`Error: paths failed to update groom.`, {type: 'warning'}),
        }
    );

    // const handleClick = () => setOpenStatusConfirm(true);
    const handleStatusDialogClose = () => setOpenStatusConfirm(false);

    const handleGroomedDialogClose = () => setOpenGroomedConfirm(false);

    const handleConfirmForStatus = () => {
        updateManyWithStatus();
        setOpenStatusConfirm(false);
    };

    const handleConfirmForGroomed = () => {
        updateManyAsGroomed();
        setOpenStatusConfirm(false);
    };

    React.useEffect(() => {
        setIsActive('');
        setSelectedIds(props.selectedIds);
    }, [props.selectedIds, selectedIds]);

    const handleChange = (event: any) => {
        setOpenStatusConfirm(true)
        setIsActive(event.target.value);
    };

    const generateUpdateStatusContent = (isActive:any) => {
        switch (isActive) {
            case Status.open:
                return 'resources.paths.fields.openStatusContent';

            case Status.closed:
                return 'resources.paths.fields.closedStatusContent';
            
            case Status.notSet:
                return 'resources.paths.fields.notSetStatusContent';

            default:
                return 'resources.paths.fields.notSetStatusContent';
        }   
    }

    return (
        <Fragment>
            <FormControl variant="standard" style={{ position: 'relative' }}>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">{translate('resources.paths.fields.setStatus')}</InputLabel>
                <Select
                    value={isActive}
                    onChange={handleChange}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        <em>{translate('resources.paths.fields.selectOne')}</em>
                    </MenuItem>
                    <MenuItem value="open" selected>{translate('resources.paths.fields.open')}</MenuItem>
                    <MenuItem value="closed">{translate('resources.paths.fields.closed')}</MenuItem>
                    <MenuItem value="notSet">---</MenuItem>
                    {/*<MenuItem value="freshlyGroomed">freshly groomed</MenuItem>*/}
                </Select>
                {/*<FormHelperText>Label + placeholder</FormHelperText>*/}
            </FormControl>
            <Confirm
                isOpen={openStatusConfirm}
                loading={loadingManyWithStatus}
                title="resources.paths.fields.updateStatusTitle"
                content={generateUpdateStatusContent(isActive)}
                onConfirm={handleConfirmForStatus}
                onClose={handleStatusDialogClose}
            />
            <Confirm
                isOpen={openGroomedConfirm}
                loading={loadingManyAsGroomed}
                title="resources.paths.fields.updateGroomedStatusTitle"
                content={"resources.paths.fields.updateGroomedStatusContent"}
                onConfirm={handleConfirmForGroomed}
                onClose={handleGroomedDialogClose}
            />
            <Button color="primary" variant="outlined" label="resources.paths.fields.groomed" onClick={() => setOpenGroomedConfirm(true)} />
            {/*<Button label="Save" onClick={handleClick} />*/}
            <BulkDeleteWithConfirmButton  {...props} />
        </Fragment>
    );
}

const PathFilter = props => {
    const { locale } = props;
    return(
        <Filter {...props}>
            <TextInput source="q" alwaysOn resettable/>
            <ReferenceInput source="areas" target="id" reference="areas" alwaysOn>
                <SelectInput variant="filled" optionText={`name.${locale}`} label="resources.paths.fields.area" emptyText="resources.paths.fields.areasEmptyText" />
            </ReferenceInput>
            <ReferenceInput source="pathCategoryId" target="id" reference="pathcategories" alwaysOn>
                <SelectInput variant="filled" optionText={`name.${locale}`}  label="resources.paths.fields.category" emptyText="resources.paths.fields.categoryEmptyText" />
            </ReferenceInput>
            <SelectInput variant="filled" source="status" emptyText="resources.paths.fields.statusEmptyText" alwaysOn choices={[
                { id: 'open', name: 'resources.paths.fields.open' },
                { id: 'closed', name: 'resources.paths.fields.closed' },
                { id: 'notSet', name: '---' },
                // { id: 'freshlyGroomed', name: 'freshly groomed' },
            ]} />
            {/*<Button variant="text" onClick={() => props.setFilters({})} label="Clear filters"/>*/}
        </Filter>
)};

const PathPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const rowStyle = (record, index) => {
    const rowCSS = {};
    if (record.status === 'closed') {
        rowCSS['backgroundColor'] = '#ffeeee';
    }
    return rowCSS;
};

export const PathList = () => {
    const [locale] = useLocaleState();
    const translate = useTranslate();

    const getDisplay = (record) => {
        if (record.display === DisplayStatus.Scheduled && record.timePeriod) {
            return `${startCase(toLower(record.display))} (${record.timePeriod.name})`;
        }
        return translate(`resources.enums.displayStatus.${startCase(toLower(record.display))}`);
    };

    const getStatus = (record) => {
        if (record.status === Status.notSet) {
            return '---';
        }
        return translate(`resources.paths.fields.${record.status}`);
    };

    return (
        <List
            perPage={25}
            pagination={<PathPagination />}
            filters={<PathFilter locale={locale}/>}
            exporter={false}
        >
            <Datagrid rowClick="edit" expand={<PathEdit/>} rowStyle={rowStyle} bulkActionButtons={<PathBulkActionButtons />}>
                <TextField source="number" />
                <TextField source={`name.${locale}`} label="resources.paths.fields.name"/>
                <DateField source="groomedAt" options={{ day: '2-digit', hour: '2-digit', minute: '2-digit', month: 'short' }}/>
                {/* <TextField source={'status'} label="resources.paths.fields.status"/> */}
                <FunctionField 
                    source="status" 
                    label="resources.paths.fields.status" 
                    render={(record) => getStatus(record)}
                />
                <TextField source={`pathCategory.name.${locale}`} label="resources.paths.fields.category" sortable={false}/>
                {/*<TextField source="area.name" label="Area" sortable={false}/>*/}

                <FunctionField
                    label="resources.paths.fields.display"
                    render={(record) => getDisplay(record)}
                />

                <ReferenceArrayField
                    source="area_ids"
                    reference="areas"
                    sortable={false}
                    label="resources.paths.fields.area"
                >
                    <SingleFieldList linkType={false}>
                        <ChipField color="secondary" source={`name.${locale}`}/>
                    </SingleFieldList>
                </ReferenceArrayField>

                {/*<TextField source="length" label="Length (km)" />
                <TextField source="difficulty" />*/}
                <ReferenceArrayField
                    source="tag_ids"
                    reference="tags"
                    sortable={false}
                >
                    <SingleFieldList linkType={false}>
                        <ChipField clickable={false} source={`name.${locale}`} color={'primary'} />
                    </SingleFieldList>
                </ReferenceArrayField>
            </Datagrid>
        </List>
    );
}

export default PathList;
