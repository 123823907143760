import germanMessages from 'ra-language-german';

export default {
    ...germanMessages,
    resources: {
        enums: {
            publishingStatus: {
                draft: "Entwurf",
                published: "Veröffentlicht",
                archived: "Archiviert",
                ready: 'Bereit'
            },
            headerType: {
                image: 'Bild',
                slideshow: 'Diashow',
                video: 'Video',
            },
            severity: {
                info: "Die Info",
                important: "Wichtig",
                warning: "Warnung",
            },
            mapConnection: {
                None: "Keiner",
                PointOfInterest: "Sehenswürdigkeit",
                AreaOfInterest: "Interessenbereich",
            },
            recurrence: {
                None: "Keiner",
                Daily: "Täglich",
                OnEntrance: "Am Eingang",
            },
            displayStatus:{
                Display: 'Anzeige',
                Hide: 'Verstecken',
                Scheduled: 'Geplant',
            }
        },
        menu: {
            dashboard: 'Dashboard',
            users: 'Benutzer',
            appSettings: 'App Einstellungen',
            settings: 'Einstellungen',
            terms: 'Geschäftsbedingungen',
            timePeriods: 'Zeit abschnitte',
            startPage: 'Startseite',
            areas: 'Bereiche',
            information: 'Information',
            tags: 'Stichworte',
            areaOfInterests: 'Interessengebiet',
            categories: 'Kategorien',
            pointOfInterests: 'Sehenswürdigkeiten',
            paths: 'Wege',
            snowmobiles: 'Schneemobile',
            recommendations: 'Empfehlungen',
            subPaths: 'Unterpfade',
            freeRidingAreas: 'Freifahrgebiete',
            grooming: 'Körperpflege',
            groups: 'Gruppen',
            crossCountryPaths: 'Langlaufwege',
            snowmobilePaths: 'Schneemobilwege',
        },
        page: {
            dashboard: {
                welcomeAdmin: 'Willkommen Admin!',
                welcomeGroomer: 'Willkommen Groomer!'
            },
        },

        users: {
            setActive: 'Aktivieren',
            selectOne: 'Wähle eins',
            yes: 'Ja',
            no: 'Nein',
            fields: {
                search: 'Suchen',
                isActive: 'Ist aktiv',
                firstName: 'Vorname',
                lastName: 'Nachname',
                email: 'Email',
                phone: 'Telefon',
                validatePhone: 'Muss ein gültiges Telefonnummernformat sein',
                role: 'Rolle',
                roles:{
                    user: 'Benutzer',
                    admin: 'Admin',
                    groomer: 'Groomer'
                },
                password: 'Neues kennwort',
                createdAt: 'Hergestellt in',
                updatedAt: 'Aktualisiert am'
            }
        },
        settings: {
            fields: {
                termsId: 'Geschäftsbedingungen',
                summerSeasonId: 'Sommersaison',
                winterSeasonId: 'Wintersaison',
                updatedAt: 'Aktualisiert am'
            }
        },
        terms:{
            fields: {
                status: 'Statuse',
                name: 'Name',
                title: 'Titel',
                terms: 'Bedingungen',
                createdAt: 'Hergestellt in',
                updatedAt: 'Aktualisiert am'
            }
        },
        timeperiods: {
            fields: {
                search: 'Suchen',
                name: 'Name',
                start: 'Start',
                end: 'Ende'
            }
        },
        areas: {
            fields: {
                search: 'Suchen',
                name: 'Name',
                createdAt: 'Hergestellt in',
                updatedAt: 'Aktualisiert am'
            }
        },
        startpages: {
            fields: {
                title: 'Titel',
                body: 'Körper',
                headerType: 'Header-Typ',
                medias: 'Medien',
                updatedAt: 'Aktualisiert am',
                priority: 'Priorität',
                name: 'Name',
                entryType: 'Eintragstyp',
            },
            editRows: 'Zeilen bearbeiten',
            create: 'Erstellen',
            editRowEntries: 'Zeileneinträge bearbeiten',
        },
        information: {
            fields: {
                status: 'Status',
                severity: 'Schwere',
                title: 'Titel',
                description: 'Beschreibung',
                startAt: 'Anfangen bei',
                endAt: 'Ende um',
                buttonText: 'Schaltflächentext',
                buttonUrl: 'Schaltflächen-URL',
                tags: 'Stichworte',
                mapConnection: 'Kartenverbindung',
                recurrence: 'Wiederauftreten',
                createdAt: 'Hergestellt in',
                updatedAt: 'Aktualisiert am'
            }
        },
        informationtags: {
            fields: {
                search: 'Suchen',
                name: 'Name',
                createdAt: 'Hergestellt in',
                updatedAt: 'Aktualisiert am'
            }
        },
        aois: {
            filterText: 'Alle Kategorien',
            fields: {
                search: 'Suchen',
                name: 'Name',
                createdAt: 'Hergestellt in',
                updatedAt: 'Aktualisiert am',
                category: 'Kategorie',
                area_ids: 'Bereich',
                areas: 'Bereiche',
                area: 'Bereich',
                display: 'Anzeige',
                description: 'Beschreibung',
                geoJsonFilename: 'Aktuelle GeoJson-Datei',
                geojson: 'Neue Geo-Json-Datei',
                timePeriodId: 'Zeitraum',
                medias: 'Medien'
            }
        },
        aoicategories: {
            fields: {
                isListed: 'Ist aufgelistet?',
                name: 'Name',
                pageTitle: 'Seitentitel',
                createdAt: 'Hergestellt in',
                updatedAt: 'Aktualisiert am',
                description: 'Beschreibung',
                media: 'Medien'
            }
        },
        pois: {
            areaFilterText: 'Alle bereiche',
            categoryFilterText: 'Alle Kategorien',
            fields: {
                areas: 'Bereiche',
                area: 'Bereich',
                area_ids: 'Bereiche',
                category: 'Kategorie',
                name:'Name',
                display: 'Anzeige',
                updatedAt: 'Aktualisiert am',
                latitude: "Breite",
                longitude: 'Längengrad',
                elevation: 'Elevation',
                url: 'url',
                description: 'Beschreibung',
                pointOfInterestCategoryId: 'Kategorie',
                timePeriodId: 'Zeitraum',
                medias: 'Medien',
            }
        },
        poicategories: {
            fields: {
                q: 'Suchen',
                isListed: 'Ist aufgelistet?',
                name: 'Name',
                pageTitle: 'Seitentitel',
                createdAt: 'Hergestellt in',
                updatedAt: 'Aktualisiert am',
                description: 'Beschreibung',
                media: 'Medien'
            }
        },
        paths: {
            fields: {
                setStatus: 'Status festlegen',
                selectOne: 'Wähle eins',
                groomed: 'Gepflegt',
                updateStatusTitle: 'Pfade mit Status aktualisieren',
                openStatusContent: 'Sind Sie sicher, dass Sie den Status für diese Elemente auf „Offen“ setzen möchten?',
                closedStatusContent: 'Sind Sie sicher, dass Sie den Status für diese Elemente auf „Geschlossen“ setzen möchten?',
                notSetStatusContent: 'Möchten Sie den Status für diese Elemente wirklich auf „nicht festgelegt“ setzen?',
                updateGroomedStatusTitle: 'Aktualisieren Sie die Pfade wie präpariert',
                updateGroomedStatusContent: 'Sind Sie sicher, dass Sie diese Gegenstände pflegen möchten?',
                q: 'Suchen',
                area: 'Bereich',
                areas: 'Bereiche',
                areasEmptyText: 'Alle Bereiche',
                category: 'Kategorie',
                display: 'Anzeige',
                categoryEmptyText: 'Alle Kategorien',
                status: 'Status',
                statusEmptyText: 'Alle Status',
                number: 'Nummer',
                name: 'Name',
                groomedAt: 'Gepflegt bei',
                tag_ids: 'Etikett',
                length: 'Länge (km)',
                start: 'startname',
                end: 'endname',
                lastGPXFilename: 'Aktuelle GPX-Datei',
                difficulty: 'Schwierigkeit',
                veryEasy: 'sehr leicht',
                easy: 'einfach',
                medium: 'mittel',
                hard: 'hart',
                open: 'offen',
                closed: 'geschlossen',
                relatedPois: 'Verwandte Pois',
                tags: 'Stichworte',
                gpx: 'GPX-Datei',
                description: 'Beschreibung',
                timePeriodId: 'Zeitraum',
                medias: 'Medien',
                createdAt: 'Hergestellt in',
                updatedAt: 'Aktualisiert am',
            }
        },
        pathcategories: {
            fields: {
                name: 'Name',
                pageTitle: 'Gepflegt bei',
                description: 'Beschreibung',
                media: 'Medien',
                createdAt: 'Hergestellt in',
                updatedAt: 'Aktualisiert am',
            }
        },
        tags: {
            fields: {
                q: 'Suchen',
                name: 'Name',
                createdAt: 'Hergestellt in',
                updatedAt: 'Aktualisiert am',
            }
        },
        'grooming/filter/paths': {
            fields: {
                q: 'Suchen',
                name: 'Name',
                filterType: 'Gruppentyp',
                crossCountry:'Skilanglauf', 
                snowmobile: 'Schneemobil',
                path_ids: 'Wege',
                subPath_ids: 'Unterwege für Schneemobile',
                createdAt: 'Hergestellt in',
                updatedAt: 'Aktualisiert am',
            }  
        },
        'grooming/paths': {
            fields: {
                setStatus: 'Status festlegen',
                selectOne: 'Wähle eins',
                groomed: 'Gepflegt',
                q: 'Suchen',
                filterpath: 'Gruppe',
                pathEmptyText: 'Alle Gruppen',
                areas: 'Bereich',
                areaEmptyText: 'Alle Bereiche',
                status: 'Status',
                statusEmptyText: 'Alle Status',
                open: 'offen',
                closed: 'geschlossen',
                number: 'Nummer',
                name: 'Name',
                groomedAt: 'Gepflegt bei',
                updateStatusTitle: 'Pfade mit Status aktualisieren',
                openStatusContent: 'Sind Sie sicher, dass Sie den Status für diese Elemente auf „Offen“ setzen möchten?',
                closedStatusContent: 'Sind Sie sicher, dass Sie den Status für diese Elemente auf „Geschlossen“ setzen möchten?',
                notSetStatusContent: 'Möchten Sie den Status für diese Elemente wirklich auf „nicht festgelegt“ setzen?',
                updateGroomedStatusTitle: 'Aktualisieren Sie die Pfade wie präpariert',
                updateGroomedStatusContent: 'Sind Sie sicher, dass Sie diese Gegenstände pflegen möchten?',
            }
        }
    },
};
