import { makeStyles } from '@mui/styles';
import * as React from "react";
import { useEffect, useState } from "react";
import {
  Edit,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  Loading,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetList,
  useGetOne,
  useRecordContext,
  TranslatableInputs,
  useLocaleState
} from "react-admin";
import { CustomToolbar } from "../../components/customtoolbar/customToolbar.component";
import GetTimePeriodRange from "../../shared/services/timePeriod.services";
import { TimePeriod } from "../../shared/interfaces/timePeriod.interface";
import { useParams } from 'react-router-dom';
import { DisplayStatus } from '../../shared/enums/display-status.enum';
import {locales} from  '../../i18n/languges';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues } from 'react-hook-form';

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const AoiEdit = () => {
  const classes = useStyles();
  const timePeriods = useGetList<TimePeriod>(
    "timeperiods",
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
    },
  );
  const record = useRecordContext();
  const [selectedTimePeriodId, setSelectedTimePeriodId] = useState<number>(0);
  const [displayValue, setDisplayValue] = useState(DisplayStatus.Display);
  const { id } = useParams();
  const [locale] = useLocaleState();
  const aoi = useGetOne("aois", { id: id ?? record?.id });
  const selectedTimePeriod = GetTimePeriodRange(
    selectedTimePeriodId,
    timePeriods.data as any
  );
  const displayOptions = Object.keys(DisplayStatus).map((key) => ({
    id: DisplayStatus[key],
    name: `resources.enums.displayStatus.${key}`,
  }));
  const schema = yup.object().shape({
    name: yup.object().shape({
        en: yup.string().required('Name in English is required'), // Assuming you need "en" for translation
    }).required('Name is required'),
    areaOfInterestCategoryId: yup.string().required('Area of Interest Category is required'),
    area_ids: yup.array().required('At least one area is required'),
    geojson: yup.mixed().required('Geo-Json file is required'), // For FileInput
    display: yup.mixed().required('Display status is required'),
    timePeriodId: yup.string().nullable().when('display', {
      is: (value: string | undefined) => value === DisplayStatus.Scheduled,
      then: schema => schema.required('Time period can\'t be empty if display is scheduled'),
      otherwise: schema => schema.nullable()
    }),
  });
  const resolver = yupResolver<FieldValues>(schema);

  useEffect(() => {
    if (!aoi.isLoading) {
      setSelectedTimePeriodId(aoi?.data?.timePeriodId);
      setDisplayValue(aoi?.data?.display);
    }
  }, [aoi.isLoading]);

  if (timePeriods.isLoading || aoi.isLoading) {
    return <Loading></Loading>;
  }
  return (
    <Edit id={record?.id}>
      <SimpleForm
        toolbar={<CustomToolbar />}
        resolver={resolver}
      >
        <TranslatableInputs fullWidth={true} locales={locales} sx={{marginBottom: '2rem'}}>
          <TextInput
            source="name"
            label="resources.aois.fields.name"
            formClassName={classes.inlineBlock}
          />
          <TextInput
            source="description"
            rows={8}
            multiline={true}
            fullWidth={true}
          />
        </TranslatableInputs>
        <ReferenceInput
          source="areaOfInterestCategoryId"
          reference="aoicategories"
        >
          <SelectInput optionText={`name.${locale}`} label="resources.aois.fields.category" />
        </ReferenceInput>
        <ReferenceArrayInput
          source="area_ids"
          reference="areas">
          <SelectArrayInput optionText={`name.${locale}`} label="resources.aois.fields.areas"/>
        </ReferenceArrayInput>
        <TextInput
          source="geoJsonFilename"
          disabled
        />
        <FileInput source="geojson" accept=".geojson">
          <FileField source="src" title="title" />
        </FileInput>
        <SelectInput
          source="display"
          choices={displayOptions}
          onChange={(e) => setDisplayValue(e.target.value as DisplayStatus)}
        ></SelectInput>
        {displayValue === DisplayStatus.Scheduled && (
          <ReferenceInput
            source="timePeriodId"
            reference="timeperiods"
            onChange={(e) => setSelectedTimePeriodId(e.target.value)}
          >
            <SelectInput
              optionText="name"
              helperText={selectedTimePeriod}
            ></SelectInput>
          </ReferenceInput>
        )}
        <ImageInput source="medias" accept="image/*" multiple={true}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="youtubeVideoId" label="YouTube id" formClassName={classes.inlineBlock}/>

        <TextInput source="socialMedia.instagramUrl" label="Instagram url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.facebookUrl" label="Facebook url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.linkedinUrl" label="Linkedin url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.twitterUrl" label="Twitter url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.vimeoUrl" label="Vimeo url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.youtubeUrl" label="Youtube url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.tripadvisorUrl" label="Tripadvisor url" formClassName={classes.inlineBlock}/>
      </SimpleForm>
    </Edit>
  );
};
